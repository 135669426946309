@use 'sass:map';
@use '_variable' as v;

$breakpoints: (
  'miniless':
    (
      max-width: 374px,
    ),
  // Small devices less/over
  'smless':
    (
      max-width: 640px,
    ),
  'smover': (
    min-width: 641px,
  ),
  // Medium devices less/over
  'mdless':
    (
      max-width: 767px,
    ),
  'mdover': (
    min-width: 768px,
  ),
  // Large devices less/over
  'lgless':
    (
      max-width: 1024px,
    ),
  'lgover': (
    min-width: 1025px,
  ),
  // Extra large devices less/over
  'xlless':
    (
      max-width: 1280px,
    ),
  'xlover': (
    min-width: 1281px,
  ),
  // Extra Extra large devices less/over
  'xxlless':
    (
      max-width: 1440px,
    ),
  'xxlover': (
    min-width: 1441px,
  ),
  // Max size devices less/over
  'maxless':
    (
      max-width: 1920px,
    ),
  'maxover': (
    min-width: 1921px,
  )
) !default;

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @error "指定されたブレイクポイントはありません -> #{map-keys($breakpoints)}";
  }
}

@mixin min($breakpoint) {
  @media screen and (min-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin max($breakpoint) {
  @media screen and (max-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin pc {
  @include mq(mdover) {
    @content;
  }
}

@mixin sp {
  @include mq(mdless) {
    @content;
  }
}

@mixin hover {
  @include pc {
    &:hover {
      @content;
    }
  }
}

@mixin clear {
  *zoom: 1;
  &:after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin yugothic() {
  font-family: YuGothic, '游ゴシック', 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif;
}

// DIN Alternate
@mixin oswald() {
  font-family: 'Oswald', sans-serif;
}

// A P-OTF A1Gothic Std
@mixin sawarabi() {
  font-family: 'Sawarabi Gothic', sans-serif;
}

@mixin impact() {
  font-family: 'Impact', sans-serif;
  font-weight: NORMAL;
}

@mixin bg-linear {
  background: linear-gradient(to right, v.$color10, v.$color9);
}
