@use '../../settings/_variable' as v;
@use '../../settings/_mixin' as m;
@use '../../settings/_path' as p;

.p-top {
  .c-footer{
    margin-top: 0;
  }

  .l-main {
    padding-top: 0 !important;
  }

  .c-header__cont {
    border-bottom: 0;
  }

  &__sc1 {
    background: url(p.$path + 'images/common/bg01.jpg') no-repeat top center/cover;
    padding-top: 61px;
    padding-bottom: 141px;
    @include m.sp {
      padding-top: v.vw(25);
      padding-bottom: v.vw(32);
    }

    .c-block01 + .c-block01 {
      margin-top: 85px;
      @include m.sp {
        margin-top: v.vw(26);
      }
    }

    .c-title01.product {
      position: relative;
      left: -20px;
      @include m.sp {
        left: -5px;
      }
      .c-title01__inner{
        @include m.sp {
          font-size: v.vw(38);
        }
      }
    }
  }

  &__sc2 {
    background-color: v.$color1;
    padding-top: 55px;
    padding-bottom: 50px;
    @include m.sp {
      padding: 0;
    }

    .c-block01 {
      @include m.sp {
        padding-right: 0;
      }
    }

    .c-title01 {
      @include m.sp {
        justify-content: center;
      }
    }
  }

  &__sc3 {
    background: url(p.$path + 'images/common/bg02.jpg') no-repeat top center/cover;
    padding-top: 144px;
    padding-bottom: 118px;
    @include m.sp {
      padding-top: 42px;
      padding-bottom: 45px;
    }
  }
}
